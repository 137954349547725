var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"defaultscroll sticky",class:{ 'bg-white': _vm.isWhiteNavbar === true },attrs:{"id":"topnav"}},[_c('div',{staticClass:"container"},[_c('div',[(_vm.navLight !== true)?_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":"/images/logo-dark.svg","height":"50","alt":""}})]):_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{staticClass:"l-dark",attrs:{"src":"/images/logo-dark.svg","height":"50","alt":""}}),_c('img',{staticClass:"l-light",attrs:{"src":"/images/logo-dark.svg","height":"24","alt":""}})])]),_c('div',{staticClass:"buy-button",staticStyle:{"width":"28% text-align: end"}},[(_vm.auth)?_c('a',{staticClass:"btn auth_btn_ch2 dropdown",class:{
            'btn-light': _vm.navLight === true,
            'btn-primary': _vm.navLight !== true,
          }},[_vm._v("My Account "),_c('div',{staticClass:"dropdown-content"},[_c('router-link',{staticClass:"btn auth_btn_ch3",staticStyle:{"margin-left":"3%","background":"transparent !important","color":"#000000 !important"},attrs:{"to":"/dashboard"}},[_vm._v("Dashboard")]),_c('a',{staticClass:"btn auth_btn_ch3",staticStyle:{"margin-left":"3%","background":"transparent !important","color":"#000000 !important"},on:{"click":_vm.onLogout}},[_vm._v("Logout")])],1)]):_vm._e(),(!_vm.auth)?_c('a',{staticClass:"btn auth_btn_ch1",class:{
            'btn-light': _vm.navLight === true,
            'btn-primary': _vm.navLight !== true,
          },attrs:{"href":"/login"}},[_vm._v("Login")]):_vm._e(),(!_vm.auth)?_c('a',{staticClass:"btn auth_btn_ch2",class:{
            'btn-light': _vm.navLight === true,
            'btn-warning': _vm.navLight !== true,
          },staticStyle:{"margin-left":"3%"},attrs:{"href":"/recruiter-registeration"}},[_vm._v("Register")]):_vm._e(),(!_vm.auth || _vm.recruiter)?_c('a',{staticClass:"btn auth_btn_ch3",staticStyle:{"margin-left":"3%","background":"#a9250f","color":"white"},attrs:{"href":"/recruit-top-talent-in-pakistan"}},[_vm._v("Recruiters   "),_c('i',{staticClass:"fa fa-caret-right",staticStyle:{"color":"white"}})]):_vm._e(),(_vm.auth && _vm.jobSeeker)?_c('a',{staticClass:"btn auth_btn_ch4",staticStyle:{"margin-left":"3%","background":"#a9250f","color":"white"},attrs:{"href":"/dashboard"}},[_vm._v(" Job Seekers   "),_c('i',{staticClass:"fa fa-caret-right",staticStyle:{"color":"white"}})]):_vm._e()]),_c('div',{staticClass:"menu-extras"},[_c('div',{staticClass:"menu-item"},[_c('a',{staticClass:"navbar-toggle",class:{ open: _vm.isCondensed === true },on:{"click":function($event){return _vm.toggleMenu()}}},[_vm._m(0)])])]),_c('div',{attrs:{"id":"navigation"}},[_c('ul',{staticClass:"navigation-menu",class:{ 'nav-light': _vm.navLight === true }},[_c('li',[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":"/jobs-in-pakistan"}},[_vm._v("Jobs")])],1),_c('li',[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"to":"/career-resources"}},[_vm._v("Career Resources")])],1),_c('div',{staticClass:"ml-10 mb-10 hide_on_dectop_ch"},[(_vm.auth)?_c('a',{staticClass:"btn auth_btn_ch2 dropdown",class:{
                'btn-light': _vm.navLight === true,
                'btn-primary': _vm.navLight !== true,
              }},[_vm._v("My Account "),_c('div',{staticClass:"dropdown-content"},[_c('router-link',{staticClass:"btn auth_btn_ch3",staticStyle:{"margin-left":"3%","background":"transparent !important","color":"#000000 !important"},attrs:{"to":"/dashboard"}},[_vm._v("Dashboard")]),_c('a',{staticClass:"btn auth_btn_ch3",staticStyle:{"margin-left":"3%","background":"transparent !important","color":"#000000 !important"},on:{"click":_vm.onLogout}},[_vm._v("Logout")])],1)]):_vm._e(),(!_vm.auth)?_c('a',{staticClass:"btn auth_btn_ch1",class:{
                'btn-light': _vm.navLight === true,
                'btn-primary': _vm.navLight !== true,
              },attrs:{"href":"/login"}},[_vm._v("Login")]):_vm._e(),(!_vm.auth)?_c('a',{staticClass:"btn auth_btn_ch2",class:{
                'btn-light': _vm.navLight === true,
                'btn-warning': _vm.navLight !== true,
              },staticStyle:{"margin-left":"3%"},attrs:{"href":"/recruiter-registeration"}},[_vm._v("Register")]):_vm._e(),(!_vm.auth || _vm.recruiter)?_c('a',{staticClass:"btn auth_btn_ch3",staticStyle:{"margin-left":"3%","background":"#a9250f","color":"white"},attrs:{"href":"/recruit-top-talent-in-pakistan"}},[_vm._v("Recruiters   "),_c('i',{staticClass:"fa fa-caret-right",staticStyle:{"color":"white"}})]):_vm._e(),(_vm.auth && _vm.jobSeeker)?_c('a',{staticClass:"btn auth_btn_ch4",staticStyle:{"margin-left":"3%","background":"#a9250f","color":"white"},attrs:{"href":"/dashboard"}},[_vm._v(" Job Seekers   "),_c('i',{staticClass:"fa fa-caret-right",staticStyle:{"color":"white"}})]):_vm._e()])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lines"},[_c('span'),_c('span'),_c('span')])}]

export { render, staticRenderFns }